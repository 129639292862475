import React, { Fragment } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';

import {
    container,
    grid,
    prev,
    next,
    linksBox,
    pageLink,
    active,
    visible,
    dots,
} from './pagination.module.scss';

import ArrowButton from '../atoms/arrow-button';

export interface IPaginationProps {
    className?: string;
    keys: string[];
    range: number;
}

const Pagination: React.FC<IPaginationProps> = ({ className = '', keys, range }) => {
    const { t, pageKey } = useI18next();
    const activeIndex = keys.indexOf(pageKey);

    return keys.length > 1 ? (
        <div className={`${container} ${className}`}>
            <div className={grid}>
                <ArrowButton
                    className={prev}
                    ariaLabel={t('pagination.prev')}
                    {...(activeIndex > 0
                        ? {
                              to: keys[activeIndex - 1],
                              as: 'link',
                          }
                        : {
                              as: 'button',
                              disabled: true,
                          })}
                />
                <div className={linksBox}>
                    {keys.map((key, index) => {
                        const isVisible = getVisibility({
                            index: index,
                            activeIndex: activeIndex,
                            paginationLength: keys.length,
                            range: range,
                        });
                        return (
                            <Fragment key={`pagination-fragment-${index}`}>
                                {index === keys.length - 1 &&
                                    activeIndex < keys.length - range - 2 && (
                                        <Link
                                            key={`pagination-link-after`}
                                            to={getMiddleKey(keys, activeIndex, range, false)}
                                            className={`${pageLink} ${dots}`}
                                        >
                                            ...
                                        </Link>
                                    )}
                                <Link
                                    key={`pagination-link-${index}`}
                                    to={key}
                                    className={`${pageLink} ${isVisible ? visible : ''}`}
                                    activeClassName={active}
                                >
                                    {index + 1}
                                </Link>
                                {index === 0 && activeIndex > range + 1 && (
                                    <Link
                                        key={`pagination-link-before`}
                                        to={getMiddleKey(keys, activeIndex, range, true)}
                                        className={`${pageLink} ${dots}`}
                                    >
                                        ...
                                    </Link>
                                )}
                            </Fragment>
                        );
                    })}
                </div>
                <ArrowButton
                    className={next}
                    ariaLabel={t('pagination.next')}
                    rotateDeg={180}
                    {...(activeIndex < keys.length - 1
                        ? {
                              to: keys[activeIndex + 1],
                              as: 'link',
                          }
                        : {
                              as: 'button',
                              disabled: true,
                          })}
                />
            </div>
        </div>
    ) : null;
};

interface IGetVisibilityConfig {
    index: number;
    activeIndex: number;
    paginationLength: number;
    range: number;
}

function getVisibility({
    index,
    activeIndex,
    paginationLength,
    range,
}: IGetVisibilityConfig): boolean {
    if (index === 0) return true;
    if (activeIndex === index) return true;
    if (activeIndex - range <= index && activeIndex + range >= index) return true;
    return paginationLength - 1 === index;
}

function getMiddleKey(
    keys: string[],
    activeIndex: number,
    range: number,
    isBefore: boolean
): string {
    let hiddenKeys;
    if (isBefore) {
        hiddenKeys = keys.slice(1, activeIndex - range);
    } else {
        hiddenKeys = keys.slice(activeIndex + range + 1, keys.length - 1);
    }
    return hiddenKeys[Math.floor(hiddenKeys.length / 2)];
}

export default Pagination;
