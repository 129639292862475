import React from 'react';

import { list, paginationBox } from './list.module.scss';
import useMediaQuery from '../../hooks/use-media-query';

import Pagination from '../molecules/pagination';

export interface IListProps {
    className?: string;
    gridClassName?: string;
    listTag?: React.ElementType;
    paginationKeys?: string[];
}

const List: React.FC<IListProps> = ({
    className = '',
    gridClassName = '',
    listTag = 'ul',
    paginationKeys = [],
    children,
}) => {
    const Tag = listTag;
    const isIPad = useMediaQuery(1025);
    return (
        <div className={className}>
            <Tag className={`${list} ${gridClassName}`}>{children}</Tag>
            <Pagination className={paginationBox} keys={paginationKeys} range={isIPad ? 2 : 3} />
        </div>
    );
};

export default List;
