import React from 'react';
import { getImage } from 'gatsby-plugin-image';

import { config } from '../config';
import { IProduct } from '../models/product.model';
import { getMediaWithRelation } from '../utils/get-relation';
import { useTranslation } from './use-translation';

const { relations, translationKeys } = config;

export interface IProductCardDataProps {
    product: IProduct;
    isDisabled?: boolean;
}

export default function useProductCardData({ product, isDisabled }: IProductCardDataProps) {
    const { name, shortName, label } = useTranslation(product, translationKeys.product);
    const { slug, labelColor } = product;

    const mediaItem = getMediaWithRelation(product.media, relations.mainImage);
    const image = mediaItem?.remoteImage && getImage(mediaItem.remoteImage);

    const handleClick = (event: React.MouseEvent) => {
        if (isDisabled) {
            event.preventDefault();
        }
    };

    return {
        name,
        shortName,
        slug,
        image,
        label,
        labelColor,
        handleClick,
    };
}
