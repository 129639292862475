import React from 'react';
import { graphql } from 'gatsby';

import { layout, catalog, listGrid, title, descriptionBox, content } from './catalog.module.scss';
import { config } from '../config';
import { ICategory } from '../models/category.model';
import { IBanner } from '../models/banner.model';
import { IProduct } from '../models/product.model';
import { ISitePage, ISitePageContext } from '../models/site-page.model';
import { useTranslation } from '../hooks/use-translation';
import { getMediaWithRelation } from '../utils/get-relation';
import { getBreadcrumbItem, getBreadcrumbs } from '../utils/get-breadcrumbs';

import SEO from '../components/seo';
import MainLayout from '../layouts/main-layout';
import CatalogNavigation from '../components/organisms/catalog-navigation';
import Title from '../components/atoms/title';
import List from '../components/organisms/list';
import ProductCard from '../components/molecules/product-card';
import Markdown from '../components/hoc/markdown';

const { translationKeys, relations } = config;

interface ICatalogProps {
    readonly data: {
        category: ICategory;
        allCategoryTree: { edges: { node: ICategory }[] };
        allProduct: { edges: { node: IProduct }[] };
        bannersCollection: {
            banners: IBanner[];
        } | null;
        categoriesSitePage: ISitePage;
        allParentCategories: { edges: { node: ICategory }[] };
    };
    readonly pageContext: ISitePageContext & {
        parentCategoryIds: number[];
        productIds: number[];
    };
}

const Catalog: React.FC<ICatalogProps> = ({ data, pageContext }) => {
    const {
        category,
        allCategoryTree,
        bannersCollection,
        allProduct,
        categoriesSitePage,
        allParentCategories,
    } = data;
    const { parentCategoryIds, paginationKeys, productIds } = pageContext;
    const { name, shortDescription, description } = useTranslation(
        category,
        translationKeys.category
    );

    const parentCategories = allParentCategories.edges.map(({ node }) => node);
    const banner = getBanner(category, bannersCollection?.banners);
    const categoryTree = allCategoryTree.edges.map(({ node }) => node);
    const products = allProduct.edges.map(({ node }) => node);
    const sortedProducts = getProductsInSequenceFromProductIdsArray(products, productIds);

    return (
        <>
            <SEO title={name} description={shortDescription} />
            <MainLayout
                className={layout}
                bannersData={banner && { banner, titleData: { Tag: 'h1' } }}
                breadcrumbsConfig={{
                    items: [
                        getBreadcrumbItem(categoriesSitePage.context),
                        ...getBreadcrumbs(parentCategories, 'name'),
                        getBreadcrumbItem(category, 'name'),
                    ],
                }}
            >
                <div className={catalog}>
                    <CatalogNavigation
                        categories={categoryTree}
                        initiallyOpen={parentCategoryIds}
                    />
                    <div className={content}>
                        <Title size="medium" className={title}>
                            {name}
                        </Title>
                        <List gridClassName={listGrid} paginationKeys={paginationKeys}>
                            {sortedProducts.map((product) => {
                                return (
                                    <li key={`product-card-${product.productId}`}>
                                        <ProductCard product={product} />
                                    </li>
                                );
                            })}
                        </List>
                        {description && (
                            <Markdown className={descriptionBox} styleType="common">
                                {description}
                            </Markdown>
                        )}
                    </div>
                </div>
            </MainLayout>
        </>
    );
};

function getProductsInSequenceFromProductIdsArray(
    products: IProduct[],
    productIds: number[]
): IProduct[] {
    const sortedProducts: IProduct[] = [];
    productIds.forEach((id) => {
        const productInSequence = products.find((product) => product.productId === id);
        if (!productInSequence) return;
        sortedProducts.push(productInSequence);
    });
    return sortedProducts;
}

function getBanner(category: ICategory, banners: IBanner[] | undefined): IBanner | undefined {
    let media = banners?.[0].media;
    const mainImageMediaItem = getMediaWithRelation(category.media, relations.mainImage);
    if (mainImageMediaItem) {
        media = category.media.filter((item) => !item.relations.includes(relations.secondaryImage));
    }
    if (media) {
        return {
            media: media,
            translations: {
                en: {
                    title: category.translations.en?.name,
                    description: category.translations.en?.shortDescription,
                },
                pl: {
                    title: category.translations.pl?.name,
                    description: category.translations.pl?.shortDescription,
                },
            },
        };
    }
}

export const query = graphql`
    query(
        $language: String!
        $categoryId: Int!
        $placeId: String!
        $productIds: [Int!]
        $parentCategoryIds: [Int!]
    ) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        category(categoryId: { eq: $categoryId }) {
            ...categoryFields
        }

        allCategoryTree(sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...categoryTreeFieldsRecursively
                }
            }
        }

        allProduct(
            filter: { productId: { in: $productIds } }
            sort: { fields: createdAt, order: DESC }
        ) {
            edges {
                node {
                    ...productCardFields
                }
            }
        }

        bannersCollection(placeId: { eq: $placeId }) {
            ...bannersCollectionFields
        }

        categoriesSitePage: sitePage(context: { pageKey: { eq: "categories" } }) {
            ...sitePageFields
        }

        allParentCategories: allCategory(filter: { categoryId: { in: $parentCategoryIds } }) {
            edges {
                node {
                    slug
                    translations {
                        en {
                            name
                        }
                        pl {
                            name
                        }
                    }
                }
            }
        }
    }
`;

export default Catalog;
