import React from 'react';

import { imageBox, header, subheader, card, ratio, labelBox } from './product-card.module.scss';
import { IProduct } from '../../models/product.model';
import useProductCardData from '../../hooks/use-product-card-data';

import RatioImage from '../atoms/ratio-image';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import ProductLabel from '../atoms/product-label';

export interface IProductCardProps {
    className?: string;
    isDisabled?: boolean;
    product: IProduct;
}

const ProductCard: React.FC<IProductCardProps> = ({
    className = '',
    isDisabled = false,
    product,
}) => {
    const { name, shortName, slug, image, handleClick, label, labelColor } = useProductCardData({
        product,
        isDisabled,
    });

    return (
        <Link className={`${card} ${className}`} to={slug} onClick={handleClick}>
            {label && labelColor && (
                <ProductLabel className={labelBox} label={label} color={labelColor} />
            )}
            <RatioImage
                image={image}
                alt={name}
                className={imageBox}
                ratioClass={ratio}
                objectFit="contain"
            />
            <h3 className={header}>{name}</h3>
            <h4 className={subheader}>{shortName}</h4>
        </Link>
    );
};

export default ProductCard;
