import React from 'react';

import { container } from './product-label.module.scss';

import Markdown from '../hoc/markdown';

interface IProductLabelProps {
    className?: string;
    label: string;
    color: string;
}

const ProductLabel: React.FC<IProductLabelProps> = ({ className = '', label, color }) => {
    return (
        <div className={`${container} ${className}`} style={{ backgroundColor: color }}>
            <Markdown>{label}</Markdown>
        </div>
    );
};

export default ProductLabel;
