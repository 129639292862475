import React, { useState } from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    list,
    closed,
    sublist,
    item,
    linkBox,
    link,
    active,
    button,
    rotate,
} from './catalog-navigation.module.scss';
import { ICategory } from '../../models/category.model';
import ChevronIcon from '../../assets/images/svg/chevron.svg';

interface ICatalogNavigationProps {
    className?: string;
    categories: ICategory[];
    initiallyOpen?: number[];
}

const CatalogNavigation: React.FC<ICatalogNavigationProps> = ({
    className = '',
    categories,
    initiallyOpen = [],
}) => {
    const { language } = useI18next();
    const [openCategories, setOpenCategories] = useState<number[]>(initiallyOpen);

    const handleToggle = (categoryId: number): void => {
        if (openCategories.includes(categoryId)) {
            setOpenCategories(openCategories.filter((id) => id !== categoryId));
            return;
        }
        setOpenCategories([...openCategories, categoryId]);
    };

    const getCategoryNavigation = (
        categories: ICategory[] | undefined,
        level = 0,
        parentId?: number
    ) => {
        if (!categories?.length) return null;
        return (
            <ul
                className={`
                    ${list} 
                    ${level > 0 ? sublist : ''} 
                    ${parentId && !openCategories.includes(parentId) ? closed : ''}
                `}
            >
                {categories.map((category) => {
                    return (
                        <li key={`catalog-nav-item-${category.categoryId}`} className={item}>
                            <div className={linkBox}>
                                <Link
                                    to={category.slug}
                                    className={link}
                                    activeClassName={active}
                                    partiallyActive={true}
                                >
                                    {category.translations[language].name} ({category.productCount})
                                </Link>
                                {category.categories && category.categories.length > 0 && (
                                    <button
                                        className={`
                                            ${button} 
                                            ${
                                                openCategories.includes(category.categoryId)
                                                    ? rotate
                                                    : ''
                                            }
                                        `}
                                        onClick={() => handleToggle(category.categoryId)}
                                    >
                                        <ChevronIcon />
                                    </button>
                                )}
                            </div>
                            {getCategoryNavigation(
                                category.categories,
                                level + 1,
                                category.categoryId
                            )}
                        </li>
                    );
                })}
            </ul>
        );
    };

    return <div className={className}>{getCategoryNavigation(categories)}</div>;
};

export default CatalogNavigation;
